'use client';

import { useEffect } from 'react';
import { useExperiments, useExposureEvent } from '@alltrails/experiments';
import dynamic from 'next/dynamic';
import { useUser } from '@alltrails/context';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

const ScrollLimit = dynamic(() => import('@alltrails/user-auth').then(module => ({ default: module.ScrollLimit })), { ssr: false });

export default function DynamicScrollLimitWrapper({ type }: { type: LocationDetailsResponseType }) {
  const user = useUser();
  const experiments = useExperiments();

  const locationExperiment = experiments['web-growth-rm-location-page-scroll-auth-location'];
  const parkExperiment = experiments['web-growth-rm-location-page-scroll-auth-park'];
  const poiExperiment = experiments['web-growth-rm-location-page-scroll-auth-poi'];

  const sendLocationExposure = useExposureEvent('web-growth-rm-location-page-scroll-auth-location');
  const sendParkExposure = useExposureEvent('web-growth-rm-location-page-scroll-auth-park');
  const sendPoiExposure = useExposureEvent('web-growth-rm-location-page-scroll-auth-poi');

  useEffect(() => {
    if (!user) {
      return;
    }

    if (type === 'location') {
      sendLocationExposure();
    } else if (type === 'park') {
      sendParkExposure();
    } else if (type === 'poi') {
      sendPoiExposure();
    }
  }, [sendLocationExposure, sendParkExposure, sendPoiExposure, type, user]);

  const shouldShowScrollLimit =
    !user &&
    ((type === 'location' && locationExperiment?.value === 'treatment') ||
      (type === 'poi' && poiExperiment?.value === 'treatment') ||
      (type === 'park' && parkExperiment?.value === 'treatment'));

  return shouldShowScrollLimit ? <ScrollLimit /> : null;
}
